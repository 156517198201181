<template>
    <div>
        <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
        <div class="d-flex align-items-center justify-content-between search-list-area">
            <div class="d-flex align-items-center search-input mb-4">
                <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
                <select v-model="list_length" class="form-control ml-2">
                    <option value="5" selected>Seçiniz</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
            <!-- <div>
                <b-button variant="outline-success" class="mr-4" @click="addCalisan = true">Çalışan
                    Ekle</b-button>

                <b-button variant="outline-primary" v-b-toggle.sidebar-right>Filtrele</b-button>

            </div> -->
        </div>

        <div class="custom-list">
            <List :header="header" :data="items" :pagination="pagination" :loading="loading" @updatePage="updatePage"
                moduleName="İş Ortakları Listesi" :moduleCreateEvent="true" :moduleSearch="search">
                <template v-slot:item.isActive="{ item }">
                    <div v-html="$customFunctions.activeBadge(item.isActive)"></div>
                </template>

                <template v-slot:item.activeStatus="{ item }">
                    <div>
                        <b-button @click="showDetailView(item)" variant="primary">Detay</b-button>
                    </div>
                </template>
                <!-- <template v-slot:item.gorevaktar="{ item }">
                    <b-button @click="gorevaktar()"
                        style="
                            font-size: 11px;
                            background-color: rgba(73, 50, 205, 0.527) !important;
                            color: white;
                        "
                        variant="light">
                        Görev Ata
                    </b-button>
                </template> -->
                <template v-slot:item.gorevaktar="{ item }">
                        <b-button
                        style="
                            font-size: 11px;
                            background-color: rgba(73, 50, 205, 0.527) !important;
                            color: white;
                        "
                        variant="light"
                        @click="gorevaktar = true"
                        >Görev Aktar</b-button
                        >
                </template>

            </List>
            <!-- Create and Actions Popups -->
        </div>
        <div class="notes-sidebar top-full">
            <b-sidebar id="sidebar-right" right>
                <div class="card dveb-gray-card aside-gray-card tab-card" style="overflow-y:auto">
                    <div class="card-header">
                        FİLTRELE

                    </div>
                    <b-tabs content-class="mt-3">
                        <b-tab content-class="tab-content" title="Filtre" active>
                            <div class="form-group">
                                <label for="sahip">Sahibi</label>
                                <select name="" id="sahip" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">Şekerbank A.Ş</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="izle">İzlenecek</label>
                                <select name="" id="izle" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">Evet</option>
                                    <option value="">Hayır</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="durum">Durum</label>
                                <select name="" id="durum" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>

                            </div>
                            <div class="form-group">
                                <label for="il">İl</label>
                                <select name="" id="il" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>

                            </div>
                            <div class="form-group">
                                <label for="ilce">İlçe</label>
                                <select name="" id="ilce" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="anahat">Anahtar Durumu</label>
                                <select name="" id="anahat" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">Var</option>
                                    <option value="">Yok</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="anahat" class="form-label">İşgalci Durumu</label>
                                <select name="" id="anahat" class="form-control">
                                    <option value="" selected>Seçiniz</option>
                                    <option value="">Var</option>
                                    <option value="">Yok</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="anahat">Ada</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="anahat">Parsel</label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-12 mt-4">
                                    <b-button variant="light" class="w-100">
                                        Temizle
                                    </b-button>
                                </div>
                                <div class="col-md-6 col-12 mt-4">
                                    <b-button variant="light" class="w-100">
                                        Uygula
                                    </b-button>
                                </div>
                                <div class="col-12 mt-4">
                                    <b-button variant="light" class="w-100">
                                        Bu Filtreyi Kaydet
                                    </b-button>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Hızlı Ara">
                            <div class="card mt-4 dveb-card-white" style="border-radius: 0 !important">
                                <div>
                                    <h4> Filtre Örnek </h4>
                                    <hr>
                                    <div class="row ml-auto">
                                        <b-button variant="success" class="mr-4">
                                            Uygula
                                        </b-button>
                                        <b-button variant="danger" class="" @click="openDeleteHandler(key)">
                                            Sil
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>

                </div>
            </b-sidebar>
        </div>
        <b-modal v-model="showDetailModal" id="modal-1" title="Çalışan Bilgileri">
            <table>
                <tbody>
                    <tr>
                        <td style="width: 40%;">
                            <b>Ad Soyad</b>
                        </td>
                        <td>: {{ selectedItem.firma }}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Sorumlu Adı </b>
                        </td>
                        <td>: {{ selectedItem.sorumlu }}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Hizmet Türü </b>
                        </td>
                        <td>: {{ selectedItem.hizmet }}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Email </b>
                        </td>
                        <td>: {{ selectedItem.email }}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Telefon </b>
                        </td>
                        <td>: {{ selectedItem.telefon }}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Hizmet Bölgesi</b>
                        </td>
                        <td>: {{ selectedItem.bolge }}</td>
                    </tr>
                </tbody>
            </table>
            <template #modal-footer>
                <div>
                    <button class="btn btn-success mr-4" @click="showDetailModal = false">Kaydet</button>
                    <button class="btn btn-danger" @click="showDetailModal = false">Kapat</button>

                </div>
            </template>
        </b-modal>
        <b-modal v-model="addCalisan" id="modal-1" title="Çalışan Ekle">
            <div class="row">
                    <div class="col-md-12 col-12">
                      <div class="form-group">
                        <label>Ad - Soyad</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Ad - Soyad"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="form-group">
                        <label>Telefon</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Telefon"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Email"
                        />
                      </div>
                    </div>
            </div>
            <template #modal-footer>
                <div>
                    <button class="btn btn-success mr-4" @click="addCalisan = false">Kaydet</button>
                    <button class="btn btn-danger" @click="addCalisan = false">Vazgeç</button>

                </div>
            </template>
        </b-modal>
        <b-modal v-model="gorevaktar" title="Görev Aktarımı">
            <div class="row">
                    <div class="col-md-12 col-12 mt-5">
                        <div class="form-group">
                        <label for="atanacakgorev">Aktarılacak Görev</label>
                        <select name="" id="atanacakgorev" class="form-control">
                            <option value="" selected>Seçiniz</option>
                            <option value="gorev1">Görev 1 </option>
                            <option value="gorev2">Görev 2</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-12 col-12 mt-5">
                        <div class="form-group">
                        <label for="aktarilacakisortagi">Aktarılacak İş Ortağı</label>
                        <select name="" id="aktarilacakisortagi" class="form-control">
                            <option value="" selected>Seçiniz</option>
                            <option value="isortagi1">İş Ortağı 1</option>
                            <option value="isortagi2">İş Ortağı 2 </option>
                        </select>
                        </div>
                    </div>
            </div>
          <template #modal-footer="{ ok, cancel, hide }">
          <b-button size="sm" variant="success" @click="gorevAktarma()">
                 Onayla
           </b-button>
          <b-button size="sm" variant="danger" @click="cancel()">
                  Vazgeç
           </b-button>
      </template>
        </b-modal>

        <DeleteOperation />
    </div>
</template>
  
<style lang="scss" scoped>
@media (max-width: 990px) {
    .search-list-area {
        flex-direction: column;

        .search-input {
            width: 100%;

            * {
                width: 100%;
            }
        }
    }

    .search-buttons {
        flex-direction: column;
    }
}

.badges-area {
    display: flex;
    gap: 1rem;

    p {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: $dveb-primary !important;
    }

    .badges {
        display: flex;
        align-items: center;
        gap: 0.2rem;

        .badge {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            padding: 0 !important;

            &.green {
                background-color: #44b6ae;
            }

            &.yellow {
                background-color: #ffac00;
            }

            &.red {
                background-color: red;
            }
        }
    }
}

.tapu-text {
    span {
        background-color: $dveb-divider;
        padding: 0.2rem 0.5rem;
        border-radius: 0.3rem;
    }
}
</style>
  
<script>
import Swal from 'sweetalert2';
export default {

    name: "customerList",

    data() {
        return {
            search: "",
            filters: "",
            showDetailModal: false,
            showInsertUpdate: false,
            showDetail: false,
            showImportModal: false,
            resetForm: false,
            confirmCreate: false,
            gorevaktar: false,
            loading: false,
            badgesEnum: ["green", "yellow", "red"],
            stepNames: ["Hazırlık", "Satış", "Devir"],
            header: [
                { text: "", value: "id", size: "50px" },
                { text: "Firma Adı", sortable: true, value: "firma" },
                { text: "Sorumlu Adı", sortable: true, value: "sorumlu" },
                { text: "Hizmet Türü", value: "hizmet" },
                { text: "Email", value: "email" },
                { text: "Telefon", value: "telefon" },
                { text: "Hizmet Bölgesi", value: "bolge" },
                { text: "Görev Ata", value: "gorevaktar" },
                { text: 'Durum', value: 'isActive', size: '120px' },
                { text: "", value: "activeStatus" },



                //   { text: "İşlemler", value: "action" },
            ],
            items: [],
            pagination: {},
            meta: {},
            listSelectedItemIds: [],
            selectedItem: "",
            deleteOperationUrl: "",
            deleteIds: "",
            filterList: [],
            list_length: 5,
            addCalisan: false

        };
    },

    created() {
        this.search = this.$route.query.search
        this.search = this.search.toLowerCase()
        this.getList();

    },
    methods: {
        getList() {
            this.loading = true;
            this.items = [
                {
                    id: 1,
                    firma: "DveB",
                    sorumlu: "Bünyamin Köse",
                    hizmet: "Yazılım",
                    email: "bunyamin@dveb.com.tr",
                    telefon: "0555 555 5555",
                    bolge: "Ataşehir/İstanbul",
                    gorevaktar: "Görev Ata",
                    isActive: true,
                    // badges: [1, 2, 3],
                },
                {
                    id: 1,
                    firma: "Karadeniz Elektrik",
                    sorumlu: "Rasim Karadeniz",
                    hizmet: "Elektrik",
                    email: "rasim@dveb.com.tr",
                    telefon: "0444 444 4444",
                    bolge: "Maltepe/İstanbul",
                    gorevaktar: "Görev Ata 2",
                    isActive: false,
                    // badges: [1, 2, 3],
                },

            ];
            this.totalItems = this.items.length;

            this.pagination = {
                current_page: 1,
                total_pages: Math.ceil(this.items?.length / this.list_length),
                total_items: this.items.length,
                list_length: this.list_length
            };
            this.loading = false;
            if (this.search) {
                const searchValue = this.search;
                const foundItems = this.items.filter((item) => {
                    for (const prop in item) {
                        if (item[prop].toString().toLowerCase().includes(searchValue)) {
                            return true;
                        }
                    }
                    return false;
                });
                this.items = foundItems;
            }

            // this.items = [];
            // this.totalItems = 0;

            // let queryLimit = this.perPage ? this.perPage : 10;
            // let query = `user/list?Page=${
            //   this.page ? this.page : 1
            // }&limit=${queryLimit}`;
            // if (this.search) {
            //   query += `&search=${this.search}`;
            // }

            // this.$dbFunctions
            //   .get(query)
            //   .then((res) => {
            //     if (res.success == true && res.count > 0) {
            //       this.items = res.data;
            //       this.totalItems = res.count;

            //       this.pagination = {
            //         current_page: res.page,
            //         total_pages: res.pageCount,
            //         total_items: res.count,
            //       };
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   })
            //   .finally(() => {
            //     this.loading = false;
            //   });
        },
        gorevAktarma() {
        Swal.fire({
        title: 'Görevi atamak istediğinize emin misiniz ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1bc5bd',
        cancelButtonColor: '#f64e60',
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteJob = !this.deleteJob
            Swal.fire(
            {
                
            title:'Başarılı!',
            text: 'Görev başarılı bir şekilde atanmıştır.',
            icon:'success',
            confirmButtonText:'Tamam',
            confirmButtonColor:'#1bc5bd'
        }
            )
        }
        })
      },
        
        updatePage(value) {
            this.meta = value;

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        openDeleteHandler(key) {
            this.$bvModal.show('modal-deleteOperation');
            let index = this.notes.indexOf(key)
            this.notes.splice(index, 1)
        },
        showDetailView(item) {
            this.selectedItem = item
            this.showDetailModal = true
        }
    },
    watch: {
        meta: {
            handler() {
                this.getList();
            },
            deep: true,
        },
        search: function () {
            this.getList();
        },
        list_length: function () {
            this.getList()
        },
        $route: function () {
            this.search = this.$route.query.search
            this.search = this.search.toLowerCase()
        }


    },

};
</script>
  